import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import moment from "moment";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import Multiselect from 'multiselect-react-dropdown';
import Placeholder from 'react-loading-skeleton';
import InfiniteScroll from "react-infinite-scroll-component";
import GoogleMapReact from "google-map-react";
import ReactDOMServer from 'react-dom/server';
import { parsePhoneNumberFromString } from "libphonenumber-js";
import qs from 'qs';
import MAPPING from "../utils/mapping";

//styles
import "../styles/pages/propertyList.scss";
import style from '../styles/pages/agentSearchPage.module.scss';
import {
    Button,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    Row,
    TabContent,
    TabPane,
    UncontrolledButtonDropdown
} from "reactstrap";

//redux
import { close } from "../redux/actions/emblemActions";

//components
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";
import PropertySuggestion from "../components/PropertySuggestion";
import PropertyCard from "../components/Layout/PropertyCard";
import ProjectCard from "../components/Layout/ProjectCard";
import CustomBreadcrumb from "../components/Breadcrumb";

//biz
import global from "../businesses/global";
import SetupData from "../data/SetupData";
import string from "../businesses/string";
import lib from "../businesses/lib";
import seoComponent from "../businesses/seoComponent";
import propertyComponent from "../businesses/property";
import projectComponent from "../businesses/projectComponent";
import auctionComponent from "../businesses/auctionProperty";
import propertyMasterComponent from "../businesses/propertyMasterComponent";

//img & icons
import nextsixAppEmblem from "../assets/home/nextsix-app-emblem.png";
import ListingNotFound from '../assets/img/listing-not-found.png'
import { MdBackspace, MdClose } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import gps from "../assets/img/locationgrey.svg";
import house from "../assets/img/housegrey.svg";
import car from "../assets/img/cargrey.svg";
import shower from "../assets/img/bathgrey.svg";
import bed from "../assets/img/bedgrey.svg";
import land from "../assets/img/icon_land_grey.svg";
import calendar from "../assets/agentSearch/whiteCalendar.svg";
import Avatar from "../components/Avatar";
import phone from "../assets/agentSearch/greenPhone.svg";
import infoIconFlag from "../assets/agentSearch/whiteInfoIcon.svg";
import Table from "../components/Table";

const handleButtonDropdown = (event) => {
    event.preventDefault();
    event.stopPropagation();
};

export default function PropertyList() {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const queryParams = qs.parse(location.search?.slice(1));
    const {
        state_or_title_or_type,
        state,
        area_or_title_or_type,
        area,
        title_or_type,
        ai,
    } = useParams();

    const filterData = useSelector(state => state.seoData);
    const titleOptions = filterData.titles || [];
    const auctionTitleOptions = filterData.bidNowPropertyTypes || [];

    //other
    const [getWidth, setGetWidth] = useState(window.innerWidth);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({})
    const maxPageButtons = 100;
    //result
    const [pageSummary, setPageSummary] = useState({});
    const [properties, setProperties] = useState([]);
    //search
    const [search, setSearch] = useState({}); //default search decoded from url to pass to filter section as default val
    // const [searchParams, setSearchParams] = useState({}); //default search formatted from search used to pass to API
    const [sorting, setSorting] = useState(queryParams.sort ? lib.getSorting(queryParams.sort) : { label: "", order: 1, column: "" });
    //map usage
    const [showMap, setShowMap] = useState(false);
    const [infoCenterModal, setInfoCenterModal] = useState(false);
    const [modalPropertyStack, setModalPropertyStack] = useState([])
    const [defaultLocation, setDefaultLocation] = useState({ lat: 3.085849, lng: 101.692614, })
    //header & desc display purpose
    const [headerTag, setHeaderTag] = useState({})

    useEffect(() => {
        const handleResize = () => setGetWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
    }, [getWidth]);

    const decodeUrlLocation = () => { //decode url to show search filter values
        let target;
        const pathname = location.pathname;
        if (pathname.startsWith(`/properties-for-sale`) || pathname.startsWith(`/ai-properties-for-sale`)) target = `properties-for-sale`;
        else if (pathname.startsWith(`/properties-for-rent`) || pathname.startsWith(`/ai-properties-for-rent`)) target = `properties-for-rent`;
        else if (pathname.startsWith(`/projects-for-sale`)) target = `projects-for-sale`;
        else if (pathname.startsWith(`/auction-properties-for-sale`)) target = `auction-properties-for-sale`;
        else target = `properties-for-sale`;

        let urlSearch = { target };

        if (ai) { //decode ai search url for /ai-properties-for-sale or /ai-properties-for-rent
            let selectedAiSearch = seoComponent.seoToState(ai);
            urlSearch.aiSearchDisplay = string.capitalize(selectedAiSearch);
            let searchContext = {};
            if (queryParams.area) {
                let selectedArea = seoComponent.treatAreaQueryParam(queryParams.area);
                searchContext.area = Array.isArray(selectedArea) ? selectedArea : [selectedArea];
            }

            if (queryParams.state) {
                let selectedState = seoComponent.treatStateQueryParam(queryParams.state);
                searchContext.state = Array.isArray(selectedState) ? selectedState : [selectedState];
            }

            if (queryParams.title) {
                let selectedTitle = seoComponent.treatTitleQueryParam(queryParams.title);
                searchContext.title = Array.isArray(selectedTitle) ? selectedTitle : [selectedTitle];
            }

            if (queryParams.master) {
                let selectedMaster = seoComponent.decodeQueryParam(queryParams.master);
                searchContext.master = (selectedMaster === 'true');
            }

            if (Object.keys(searchContext).length > 0) urlSearch.aiSearchContext = searchContext;
            return urlSearch;
        }

        //below for normal url decode
        const isTitleOrType = (val, target) => { //handle different checking for auction and properties
            if (target === global.AUCTION) return seoComponent.isTitleV2(val, auctionTitleOptions);
            return SetupData.SEOTitleOrType.includes(val);
        }
        const decodeTitleOrType = (val) => {
            if (target === global.AUCTION || seoComponent.isTitleV2(val, titleOptions, queryParams)) {
                urlSearch.titleOrType = val;
            } else { //handle selected type.length = 1
                const titleType = seoComponent.getTitleFromType(val, titleOptions);
                urlSearch = {
                    ...urlSearch,
                    ...titleType,
                }
            }
        }
        const decodeState = (val) => {
            urlSearch.state = seoComponent.beautifyState(val);
        }
        const decodeArea = (val) => {
            urlSearch.area = seoComponent.beautifyArea(val);
        }
        const decodeQueryParams = (queryParamsObj) => {
            const noDecodeKeys = ["auction_start", "auction_end", "types"]; //fields that does not need decodeURI

            let queryParamSearch = {};

            Object.keys(queryParamsObj).forEach(key => {
                let searchKey = key;
                let searchVal = String(queryParamsObj[key]);
                let decode = true;
                if (key === "query") searchKey = "freeText";
                else if (key === "min_price") searchKey = "minPrice";
                else if (key === "max_price") searchKey = "maxPrice";
                else if (key === "property") searchKey = "propertyName";
                else if (key === "min_buildup") searchKey = "minBuildup";
                else if (key === "max_buildup") searchKey = "maxBuildup";
                else if (key === "min_landarea") searchKey = "minLandArea";
                else if (key === "max_landarea") searchKey = "maxLandArea";
                else if (key === "master") searchKey = "realestateMaster";
                else if (key === "auction_start") searchKey = "auctionStart";
                else if (key === "auction_end") searchKey = "auctionEnd";
                else if (key === "page") searchKey = "page";

                if (noDecodeKeys.includes(key)) decode = false;

                if (decode) searchVal = seoComponent.decodeQueryParam(searchVal);

                if (key === "types") {
                    const typesArr = searchVal.split(',');
                    searchVal = typesArr.map(type => seoComponent.beautifyTitleOrType(type));
                }
                else if (key !== "query") searchVal = string.capitalize(searchVal);

                queryParamSearch[searchKey] = searchVal;
            });
            return queryParamSearch;
        }

        if (state_or_title_or_type) {
            //also handle for auction title checking, pass in target for checking
            if (isTitleOrType(state_or_title_or_type, target)) decodeTitleOrType(state_or_title_or_type);
            else decodeState(state_or_title_or_type);
        }

        if (state) decodeState(state);

        if (area_or_title_or_type) {
            if (isTitleOrType(area_or_title_or_type)) decodeTitleOrType(area_or_title_or_type);
            else decodeArea(area_or_title_or_type);
        }

        if (area) decodeArea(area);

        if (title_or_type) decodeTitleOrType(title_or_type);

        const decodedQP = decodeQueryParams(queryParams);

        const finalUrlSearch = {
            ...urlSearch,
            ...decodedQP,
        }

        return finalUrlSearch;
    }

    const formatSearchParams = (urlSearch) => {
        const specialFormatKey = [
            "minPrice",
            "maxPrice",
            "minBuildup",
            "maxBuildup"
        ]
        let searchParam = {};
        Object.keys(urlSearch).forEach(key => {
            if (!specialFormatKey.includes(key)) {
                let searchKey = key;
                let searchVal = urlSearch[key];
                if (key === "carParks") searchKey = "carparks";
                // else if (key === "types" && Array.isArray(searchVal)) {
                //     searchVal = searchVal.map(type => lib.toHyphen(type));
                // }

                searchParam[searchKey] = searchVal;
            }
        });
        if (urlSearch.minPrice || urlSearch.maxPrice) {
            searchParam.price = {};
            if (urlSearch.minPrice) searchParam.price.min = urlSearch.minPrice;
            if (urlSearch.maxPrice) searchParam.price.max = urlSearch.maxPrice;
        }
        if (urlSearch.minBuildup || urlSearch.maxBuildup) {
            searchParam.buildup = {};
            if (urlSearch.minBuildup) searchParam.buildup.min = urlSearch.minBuildup;
            if (urlSearch.maxBuildup) searchParam.buildup.max = urlSearch.maxBuildup;
        }
        //for auction search
        if (urlSearch.minLandArea || urlSearch.maxLandArea) {
            searchParam.landArea = {};
            if (urlSearch.minLandArea) searchParam.landArea.min = urlSearch.minLandArea;
            if (urlSearch.maxLandArea) searchParam.landArea.max = urlSearch.maxLandArea;
        }
        if (urlSearch.auctionStart || urlSearch.auctionEnd) {
            searchParam.auctionDate = {};
            if (urlSearch.auctionStart) searchParam.auctionDate.start = urlSearch.auctionStart;
            if (urlSearch.auctionEnd) searchParam.auctionDate.end = urlSearch.auctionEnd;
        }
        return searchParam;
    }

    const processURL = (type) => {
        let urlPath = window.location.pathname
        let isEndsWith = false;

        for (const titleOrType of SetupData.SEOTitleOrType) {
            if (urlPath.endsWith(titleOrType) && type !== "/") {
                isEndsWith = true;
                urlPath = urlPath.slice(0, -titleOrType.length) + type
                break;
            } else if (urlPath.endsWith(titleOrType) && type === "/") {
                // remove the "/" at the end when navigate from type to state/area
                urlPath = urlPath.slice(0, -titleOrType.length - 1)
                break;
            }
        }
        // do nothing to the url if the type is "/"
        if (!isEndsWith && type !== "/") {
            urlPath = urlPath + "/" + type
        }

        return urlPath
    }

    const processMetaData = (urlSearch) => {
        // console.log(urlSearch)
        let _for = `Sale`;
        if (urlSearch.target === global.RENT) _for = 'Rent';

        let searches = { ...urlSearch };

        let niceState, niceArea, niceTitleOrType;
        if (searches.state) niceState = searches.state;
        if (searches.area) niceArea = searches.area;
        if (searches.titleOrType) niceTitleOrType = seoComponent.beautifyTitleOrType(searches.titleOrType);

        let headerText = {
            for: _for.toLowerCase(),
            place: "Malaysia",
            cPlace1: "Malaysia",
        };

        let hasArea = false;

        if (searches.target === global.BUY || searches.target === global.RENT) {
            headerText.type = "properties";
            headerText.show = true; //to show header2 & para1
        } else if (searches.target === global.PROJECT) headerText.type = "projects";
        else if (searches.target === global.AUCTION) headerText.type = "auctions";

        if (niceTitleOrType) {
            if (searches.target !== global.AUCTION) {
                // isTypes check for if the types array contains two or more element
                var isTypes
                if (searches.types) {
                    isTypes = Array.isArray(searches.types) && searches.types?.length > 1
                }
                const isTitle = seoComponent.isTitle(searches.types || searches.titleOrType);

                if (isTitle) headerText.type = niceTitleOrType;
                else {
                    // usage of isTypes
                    const simplifiedTitleOrType = seoComponent.simplifyTitleOrType(isTypes ? searches.titleOrType : lib.toHyphen(searches.types[0]));
                    headerText.type = simplifiedTitleOrType;
                }
            }
        }
        if (niceState) {
            if (niceArea) {
                hasArea = true;
                headerText.place = `${niceArea}, ${niceState}`;
                headerText.cPlace1 = niceArea;
            } else {
                headerText.place = `${niceState}`;
                headerText.cPlace1 = niceState;
            }
        }

        // const header1 = ` found of ${headerText.type} for ${headerText.for} in ${headerText.place}`;
        const header2 = headerText.show ? ` found of ${headerText.type} for ${headerText.for} in ${headerText.place}` : ` found`;
        // const paragraph1 = `Welcome to ${headerText.cPlace1}, where comfort meets convenience.
        // <span key=${headerText.type}> <a href=${processURL(searches.titleOrType)}></a></span>`
        setHeaderTag({
            // header1,
            header2,
            // paragraph1,
            searchedType: searches.titleOrType,
            state: niceState,
            hasArea: hasArea,
            type: headerText.type,
            cKey1: headerText.for,
            cPlace1: headerText.cPlace1,
            show: headerText.show,
        })
    };

    useEffect(() => {
        const performSearch = async () => {
            const urlSearch = decodeUrlLocation();
            const params = formatSearchParams(urlSearch);
            const newUrl = seoComponent.createSearchUrl({ ...urlSearch, page: urlSearch.page, sort: sorting.label });
            history.push(newUrl);

            let searchFunction = propertyComponent.webRevampSearch;
            if (urlSearch.target === global.PROJECT) searchFunction = projectComponent.projectWebSearch;
            else if (urlSearch.target === global.AUCTION) searchFunction = auctionComponent.auctionWebSearch;
            setPage(parseInt(urlSearch.page))

            // setPreviousSearch(urlSearch)
            const result = await searchFunction(params,
                {
                    page: urlSearch.page,
                    sortColumn: sorting.column,
                    sortOrder: sorting.order
                },
            )
            if (result) {
                setPageSummary(result.summary);
                const propertiesData = result.data;
                setProperties(propertiesData);

                if (propertiesData[0] && propertiesData[0].loc && propertiesData[0].loc?.coordinates) {
                    setDefaultLocation({ lat: propertiesData[0].loc?.coordinates[1], lng: propertiesData[0].loc?.coordinates[0] })
                }
                else if (propertiesData[1] && propertiesData[1].loc && propertiesData[1].loc?.coordinates) {
                    setDefaultLocation({ lat: propertiesData[1].loc?.coordinates[1], lng: propertiesData[1].loc?.coordinates[0] })
                }

                // setSeoUrl(getSeoUrl(urlLocation.pathname, urlLocation.search))
            }
            if (urlSearch.realestateMaster) { //get property master name for property suggestion default text
                const masterProp = await propertyMasterComponent.id(urlSearch.realestateMaster);
                if (masterProp) urlSearch.masterName = masterProp.name;
            }
            setSearch(urlSearch);
            // setSearchParams(params);
            processMetaData(urlSearch);
            setLoading(false);
        }
        performSearch();
    }, [sorting]);

    useEffect(() => {
        if (search.page > pageSummary.pages) {
            //error handling if user input query more than actual
            console.log('query page is more than actual page')
        }

    }, [pageSummary])

    const handlePaginationClick = (event, pageNumber) => {
        setSearch(search => ({
            ...search,
            page: pageNumber,
            sort: sorting.label
        }));

        const newUrl = seoComponent.createSearchUrl({ ...search, page: pageNumber, sort: sorting.label });
        window.location = newUrl;
    }

    const toggleMap = () => {
        setShowMap(!showMap);
    }

    const goToAgentPage = (agent) => {
        const newUrl = seoComponent.createAgentDetailUrl(agent);
        return newUrl;
    };

    const handlePhone = (mobile) => {
        const phone = parsePhoneNumberFromString(mobile, "MY").nationalNumber;
        window.location = `tel:+60${phone}`;
    };

    const handleModalPopulateData = (property) => {
        if (property.for) {
            const newUrl = seoComponent.createPropertyDetailUrl(property);
            return newUrl;
        }
        else if (!property.for && property.referenceNo != "") {//for map info window auction cant if else inside
            const newUrl = seoComponent.createAuctionDetailUrl(property);
            return newUrl;
        }
    };

    const handlePopulateData = (property, from) => {
        if (property.for) {
            localStorage.setItem('from', from);
            const newUrl = seoComponent.createPropertyDetailUrl(property);
            return newUrl;
        }
        else if (!property.for && property.referenceNo !== "") {//for map info window auction cant if else inside
            localStorage.setItem('from', from);
            const newUrl = seoComponent.createAuctionDetailUrl(property);
            return newUrl;
        }
    };

    const handleProjectPopulateData = (property, from) => {
        localStorage.setItem('from', from);
        const newUrl = seoComponent.createProjectDetailUrl(property);
        return newUrl;
    };

    const handleAuctionPopulateData = (property, from) => {
        localStorage.setItem('from', from);
        const newUrl = seoComponent.createAuctionDetailUrl(property);
        return newUrl;
    }

    const handleModalStack = (propertyStack) => {
        setModalPropertyStack(propertyStack)
        toggleInfoCenterModal()
    }

    const toggleInfoCenterModal = () => {
        setInfoCenterModal(!infoCenterModal)
    }

    const Sorter = () => {
        const handleSortColumnChange = (event) => {
            const label = event.target.value;
            const sortObj = lib.getSorting(label);
            setSorting(sortObj);
        };

        return (
            <div className="property-list-table-sorter">
                <label className="property-list-sorter-label">{t('sort-by')}:</label>
                <select
                    className="property-list-sorter-select"
                    name="sortColumn"
                    onChange={handleSortColumnChange}
                    value={sorting.label}
                >
                    {SetupData.sortColumn.propertyList.map((sortColumn, index) => {
                        return (
                            <option
                                className="property-list-sorter-option"
                                key={index}
                                value={sortColumn.label}
                            >
                                {t(sortColumn.translateKey)}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    };

    const ModelsMap = (map, maps) => {
        const groupPropertyStack = properties.reduce((tmp, x) => {
            // item to treat
            const match = tmp.find(y => y.some(z => (z.address.area === x.address.area && z.masterName === x.masterName && (z.masterName != null && x.masterName != null) && z.realestateMaster === x.realestateMaster && (z.realestateMaster != null && x.realestateMaster != null))));//to group all same area who stack//propertymaster

            // If we have such array, push the data into it//that stack
            if (match) {
                match.push(x);
            } else {
                // If we don't create a new array
                tmp.push([x]);
            }
            return tmp;
        }, []);

        if (groupPropertyStack) {
            for (let i = 0; i < groupPropertyStack.length; i++) {
                if (groupPropertyStack[i].length >= 1) {
                    let propertyStack = groupPropertyStack[i]
                    if (propertyStack.length >= 2 && propertyStack[0].masterName) {//larger or equal to 2 that in same stacking && 
                        //this might use modal display [{},{},{}] no info window/ onlick set modal with scroll bar 
                        const size = string.getWidth(propertyStack[0].masterName + " +" + propertyStack.length)

                        let marker = new maps.Marker({
                            position: {
                                lat: propertyStack[0].loc?.coordinates[1],//cause stacking so took the 1st loc
                                lng: propertyStack[0].loc?.coordinates[0],
                            },
                            map,
                            label: {
                                text: propertyStack[0].masterName + " +" + propertyStack.length,//propertyStack[0].masterName
                                color: "#ffffff",
                                fontWeight: "bold",
                                className: "property-list-Maplabel-infowindow"
                            },
                            icon: {
                                url: 'https://maps.gstatic.com/mapfiles/transparent.png',
                                origin: new maps.Point(0, 0),
                                scaledSize: new maps.Size(size, 28)
                            }
                        });
                        marker.addListener("click", () => {
                            handleModalStack(propertyStack)
                        });
                    }

                    else {//stack have or no mastername (show 1st)neglect other/ only one  , *** one loc only allow one property
                        // console.log(propertyStack,"w/o master   or ( stack but 1st data on same loc dont have master)")//this normal display propertyStack[0]//will not display modal
                        if (propertyStack[0].loc) {
                            if (propertyStack[0]) {
                                let label = ""
                                let propertyFor = ""
                                let propertyRentType = ""
                                let price = ""
                                let datedisplay = ""

                                if (propertyStack[0].for && (propertyStack[0].for.toLowerCase() === "rent" || propertyStack[0].for.toLowerCase() === "buy")) {
                                    label = propertyStack[0].for.toLowerCase() === "rent" ? "RM " + lib.currencyFormatter(propertyStack[0].rent.monthlyRental)
                                        : "RM " + lib.currencyFormatter(propertyStack[0].buy.price)

                                    propertyFor = propertyStack[0].for.toLowerCase() === "buy" ? lib.toTitleCase("buy") : lib.toTitleCase("rent");

                                    propertyRentType = propertyStack[0].for.toLowerCase() === "rent" ? propertyStack[0].rentType || "" : "";

                                    price = propertyStack[0].for.toLowerCase() === "rent" ?
                                        `RM ${parseFloat(propertyStack[0].rent.monthlyRental).toLocaleString()}/month`
                                        : "RM " + parseFloat(propertyStack[0].buy.price).toLocaleString();
                                    datedisplay = propertyStack[0].updated ? propertyStack[0].updated : propertyStack[0].created
                                }
                                else {
                                    label = "RM " + lib.currencyFormatter(propertyStack[0].price)
                                    propertyFor = "Auction"
                                    price = "RM " + parseFloat(propertyStack[0].price).toLocaleString();
                                    datedisplay = propertyStack[0].auctionDate ? propertyStack[0].auctionDate : propertyStack[0].created
                                }

                                const propertyPng = propertyStack[0].images && propertyStack[0].images.length > 0 ? propertyStack[0].images[0] : SetupData.defaultHouse;
                                const addressArea = propertyStack[0].address.area ? propertyStack[0].address.area + "," : ""
                                const title = propertyStack[0].title ? propertyStack[0].title : "-";
                                const numOfBed = propertyStack[0].beds ? (propertyStack[0].beds !== "Bedrooms" && propertyStack[0].beds !== "Beds") ? propertyStack[0].beds : "-" : "-";
                                const numOfBath = propertyStack[0].baths ? propertyStack[0].baths : "-";
                                const numOfCarPark = propertyStack[0].carParks ? propertyStack[0].carParks : "-";
                                const numOfLandArea = propertyStack[0].landArea ? propertyStack[0].landArea : "-";
                                const numOfBuildUp = propertyStack[0].buildup ? propertyStack[0].buildup : "-";

                                let link = "https://google.com"
                                if (propertyStack[0].for) {
                                    link = seoComponent.createPropertyDetailUrl(propertyStack[0])
                                }//multiple if for diff secnario //local/sit/next six took from env or anyway
                                else if (!propertyStack[0].for && propertyStack[0].referenceNo !== "") {
                                    link = seoComponent.createAuctionDetailUrl(propertyStack[0])
                                }

                                const info = (
                                    <div>
                                        <a href={link} style={{ 'textDecoration': 'none' }} rel="noopener noreferrer" target="_blank" onClick={() => localStorage.setItem('from', "map")} >
                                            <div className='property-list-card'>
                                                <div className='property-map-card' >
                                                    <div>
                                                        <img loading="lazy" src={propertyPng} className='info-card-image' alt={propertyStack[0] && (propertyStack[0].for == "Rent" || propertyStack[0].for == "Buy") && (propertyStack[0].type + " " + (propertyStack[0].for == "Rent" ? "For Rent" : "For Buy") + " in " + propertyStack[0].address?.area + " " + propertyStack[0].address?.state.replace(`Wilayah Persekutuan`, ``).trim())} />
                                                    </div>
                                                    <br />
                                                    <div className='property-list-pricelabel' >
                                                        <div className='property-list-statuslabel-slide'>
                                                            <div className='property-list-statuslabel'>
                                                                {propertyFor}
                                                            </div>
                                                            {propertyRentType && <div className='property-list-statuslabel rent-type'>
                                                                {propertyRentType}
                                                            </div>}
                                                            <div className='property-list-date'>
                                                                <span className='lightgreytext'>
                                                                    {propertyFor === "Auction" ? <>{'Auction Date on'} {moment(datedisplay).format("DD/MM/YYYY")}</> : <>{'Last Updated on'} {moment(datedisplay).format("DD/MM/YYYY")}</>}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <p className='mt-2 mb-2 greytext property-list-address'>
                                                            <img loading="lazy" src={gps} className='locationlogo' alt="" />{addressArea}, {propertyStack[0].address.state}
                                                        </p>
                                                        <div className='property-list-price'>{price}</div>
                                                        <div className='property-list-card-name'><b >{propertyStack[0].name} </b></div><br />
                                                        <span className='property-card-facilitylogo-padding'>
                                                            <img loading="lazy" src={bed} className='facilitylogo' alt="" /><span className="facilitylogo-description">{numOfBed}</span>
                                                            <img loading="lazy" src={shower} className='facilitylogo' alt="" /><span className="facilitylogo-description">{numOfBath}</span>
                                                            <img loading="lazy" src={car} className='facilitylogo' alt="" /><span className="facilitylogo-description">{numOfCarPark}</span>
                                                            {(() => {
                                                                if (title === 'Agricultural Land' || title === 'Commercial Land'
                                                                    || title === 'Industrial Land' || title === 'Residential Land') {
                                                                    if (numOfLandArea) {
                                                                        return <><img loading="lazy" src={land} className='facilitylogo' alt="" /><span className="facilitylogo-description">{numOfLandArea}sqft</span></>
                                                                    }
                                                                } else {
                                                                    if (numOfBuildUp) {
                                                                        return <> <img loading="lazy" src={house} className='facilitylogo' alt="" /><span className="facilitylogo-description">{numOfBuildUp} sqft </span></>
                                                                    }
                                                                }
                                                            })()}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )

                                const infowindow = new maps.InfoWindow({
                                    content: ReactDOMServer.renderToString(info),
                                    maxWidth: 370,
                                    maxHeight: 600,
                                });

                                const size = string.getWidth(label)

                                let marker = new maps.Marker({
                                    position: {
                                        lat: propertyStack[0].loc?.coordinates[1],
                                        lng: propertyStack[0].loc?.coordinates[0],
                                    },
                                    map,
                                    label: {
                                        text: label,
                                        color: "#ffffff",
                                        fontWeight: "bold",
                                        className: "property-list-Maplabel-infowindow"
                                    },
                                    icon: {
                                        url: 'https://maps.gstatic.com/mapfiles/transparent.png',
                                        origin: new maps.Point(0, 0),
                                        scaledSize: new maps.Size(size, 28)
                                    },
                                });

                                marker.addListener("dblclick", () => {
                                    handlePopulateData(propertyStack[0], "map")
                                });

                                marker.addListener("click", () => {
                                    infowindow.open({
                                        anchor: marker,
                                        map,
                                        shouldFocus: false,
                                    });
                                    handlePopulateData(propertyStack[0], "map")
                                });
                            }
                        }
                    }
                }
            }
        }
    };

    const ProjectsMap = (map, maps) => {
        //instantiate array that will hold your Json Data
        let dataArray = [];
        //push your Json Data in the array

        properties.map((markerJson) => dataArray.push(markerJson));

        //Loop through the dataArray to create a marker per data using the coordinates in the json
        for (let i = 0; i < dataArray.length; i++) {
            if (dataArray[i].loc) {
                if (dataArray[i].price) {
                    const label = dataArray[i].price.min && "RM " + lib.currencyFormatter(dataArray[i].price.min)
                    const propertyPng = dataArray[i].images && dataArray[i].images.length > 0 ? dataArray[i].images[0] : SetupData.defaultHouse;
                    const propertyFor = "NewProject";
                    const price = dataArray[i].price.min && " From RM " + parseFloat(dataArray[i].price.min).toLocaleString();
                    const numOfBed = dataArray[i].bed && dataArray[i].bed.min && dataArray[i].bed.max ? dataArray[i].bed.min + "-" + dataArray[i].bed.max : "-";
                    const numOfBath = dataArray[i].bath && dataArray[i].bath.min && dataArray[i].bath.max ? dataArray[i].bath.min + "-" + dataArray[i].bath.max : "-";
                    const numOfBuildUp = dataArray[i].buildup?.min ? "From " + dataArray[i].buildup.min : "-";
                    const developerLogo = dataArray[i].developer?.logo || SetupData.defaultHouse;
                    let link = "https://google.com"
                    if (dataArray[i]) { link = seoComponent.createProjectDetailUrl(dataArray[i]) }//multiple if for diff secnario //local/sit/next six took from env or anyway

                    const info = (
                        <div>
                            <a href={link} style={{ 'textDecoration': 'none' }} rel="noopener noreferrer" target="_blank" onClick={() => localStorage.setItem('from', "map")}>
                                <div className='property-list-card'>
                                    <div className='property-map-card' >
                                        <div>
                                            <img loading="lazy" src={propertyPng} className='info-card-image' alt="" />
                                        </div>
                                        <br />
                                        <div className='property-list-pricelabel' >
                                            <div className='property-list-statuslabel-slide'>
                                                <span className='m-0 property-list-global.PROJECT-statuslabel'>
                                                    {propertyFor}
                                                </span>
                                                <span className='property-list-dev-company'>
                                                    <img loading="lazy" src={developerLogo} className='property-list-dev-company-logo' alt="" />
                                                </span>
                                            </div>
                                            <p className='mb-2 greytext property-list-address'>
                                                <img loading="lazy" src={gps} className='locationlogo' alt="" />{dataArray[i].address.area}, {dataArray[i].address.state}
                                            </p>
                                            <div className='property-list-price'>{price}</div>
                                            <div className='property-list-card-name'><b >{dataArray[i].name} </b></div><br />
                                            <span className='property-card-facilitylogo-padding'>
                                                <img loading="lazy" src={bed} className='facilitylogo' alt="" /><span className="facilitylogo-description">{numOfBed}</span>
                                                <img loading="lazy" src={shower} className='facilitylogo' alt="" /><span className="facilitylogo-description">{numOfBath}</span>
                                                <img loading="lazy" src={house} className='facilitylogo' alt="" /><span className="facilitylogo-description">{numOfBuildUp} sqft </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )

                    const infowindow = new maps.InfoWindow({
                        content: ReactDOMServer.renderToString(info),
                        maxWidth: 370,
                        maxHeight: 600,
                    });

                    const size = string.getWidth(label)

                    let marker = new maps.Marker({
                        position: {
                            lat: dataArray[i].loc?.coordinates[1],
                            lng: dataArray[i].loc?.coordinates[0],
                        },
                        map,
                        label: {
                            text: label,
                            color: "#ffffff",
                            fontWeight: "bold",
                            className: "property-list-Maplabel-infowindow"
                        },
                        icon: {
                            url: 'https://maps.gstatic.com/mapfiles/transparent.png',
                            origin: new maps.Point(0, 0),
                            scaledSize: new maps.Size(size, 28)
                        }
                    });

                    marker.addListener("dblclick", () => {
                        handleProjectPopulateData(dataArray[i], "map")
                    });

                    marker.addListener("click", () => {
                        infowindow.open({
                            anchor: marker,
                            map,
                            shouldFocus: false,
                        });
                        handleProjectPopulateData(dataArray[i], "map")
                    });
                }
            }
        }
    };

    const PropertyRows = ({ }) => {
        const type = 'properties';

        return (
            <tr className={clsx("propSearch-listing",
                { "propSearch-listing-five-col": getWidth > 1199 && !showMap },
                { "propSearch-listing-three-col": getWidth > 1199 && showMap },
                { "propSearch-listing-four-col": getWidth < 1200 && !showMap },
                { "propSearch-listing-two-col": getWidth < 1200 && showMap },
                { "propSearch-listing-two-col": getWidth < 769 && !showMap },
                { "propSearch-listing-two-col": getWidth < 769 && showMap },
            )}>
                {properties.map((property) => {
                    return (
                        <td key={property._id}>
                            {search.target === global.PROJECT ?
                                <a href={handleProjectPopulateData(property, "listing")} onClick={() => localStorage.setItem('from', "listing")} className="property-row-redirectLink2">
                                    <ProjectCard property={property} type={type} />
                                </a> :
                                search.target === global.AUCTION ?
                                    <a href={handleAuctionPopulateData(property, "listing")} onClick={() => localStorage.setItem('from', "listing")} className="property-row-redirectLink2">
                                        <PropertyCard property={property} type={type} showAgent={false} />
                                    </a> :
                                    <a href={handlePopulateData(property, "listing")} onClick={() => localStorage.setItem('from', "listing")} className="property-row-redirectLink2">
                                        <PropertyCard property={property} type={type} showAgent={false} />
                                    </a>}
                        </td>
                    );
                })}
            </tr>
        );
    };

    const UrlNav = () => {
        const finalUrlSearch = decodeUrlLocation();
        const propertyFor = finalUrlSearch.target.split("-").pop();
        const urlFor = `/${finalUrlSearch.target}`;

        const searchData = {
            home: {
                title: "Home",
                url: "/",
            },
            for: {
                title: `For ${lib.toTitleCase(propertyFor)}`,
                url: urlFor,
            },
            state: {
                title: finalUrlSearch.state,
                url: "",
            },
            area: {
                title: finalUrlSearch.area,
                url: "",
            },
            title: {
                title: finalUrlSearch.titleOrType,
                url: "",
            },
            types: {
                title: finalUrlSearch.types,
                url: "",
            }
        };

        if (finalUrlSearch.state) {
            searchData.state.url = `${urlFor}/${seoComponent.treatState(
                searchData.state.title
            )}`;
        }
        if (finalUrlSearch.area) {
            searchData.area.url = `${searchData.state.url}/${seoComponent.treatArea(
                searchData.area.title
            )}`;
        }
        if (finalUrlSearch.titleOrType) {
            searchData.title.url = `${searchData.area.url !== ""
                ? `${searchData.area.url + "/"}` : searchData.state.url !== ""
                    ? `${searchData.state.url + "/"}`
                    : `${urlFor}/`
                }${seoComponent.treatTitleOrType(finalUrlSearch?.titleOrType)}`;
            searchData.title.title = seoComponent.beautifyTitleOrType(
                finalUrlSearch.titleOrType
            );
        }
        if (finalUrlSearch.types) {
            for (const c of finalUrlSearch.types) {
                searchData.types.url = `${searchData.area.url !== ""
                    ? `${searchData.area.url + "/"}`
                    : searchData.state.url !== ""
                        ? `${searchData.state.url + "/"}`
                        : `${urlFor}/`
                    }${seoComponent.treatTitleOrType(c)}`;
                break;
            }
            if (finalUrlSearch.types.length > 1) searchData.types.title = undefined;
        }

        return <CustomBreadcrumb location={location} items={searchData} />;
    };

    // const CustomBreadcrumb = ({ items }) => {
    //   const pathname = location.pathname.split("/").pop();

    //   return (
    //     <Breadcrumb>
    //       {Object.keys(items).map((item, index) => {
    //         return (
    //           items[item].title !== undefined && (
    //             <>
    //               <Breadcrumb.Item
    //                 key={index}
    //                 href={items[item].url}
    //                 active={items[item].url.split("/").pop() === pathname}
    //               >
    //                 {items[item].title}
    //               </Breadcrumb.Item>
    //             </>
    //           )
    //         );
    //       })}
    //     </Breadcrumb>
    //   );
    // };

    const ContentDescription = () => {
        const { InternalLinkings } = MAPPING;
        const { searchedType, hasArea, type, cPlace1, cKey1, state } = headerTag;

        const matchingLinks = InternalLinkings.filter(
            (internal) => internal.link === searchedType
        );
        const additionalLinks = InternalLinkings.filter(
            (internal) => internal.link !== searchedType
        );

        const getInternalLinks = (links) =>
            links.map((internalLinking) => (
                <span key={internalLinking.name}>
                    <a href={processURL(internalLinking.link)}>
                        {string.replaceString(internalLinking.name, { state: cPlace1 })}
                    </a>
                    {internalLinking.link === "/" ? "" : ", "}
                </span>
            ));

        const commonContent = (
            <>
                Experience the convenience of our comprehensive platform where you
                can search for properties efficiently and confidently. Explore our
                wide range of listings and discover the best prices to find your
                perfect match today!
            </>
        );

        const getContent = () => {
            if (hasArea && type === "properties") {
                // Area content without types
                return {
                    content1: (
                        <>
                            Unveil your dream lifestyle in {cPlace1}, where comfort
                            and convenience seamlessly intertwine. Explore a diverse
                            selection of new and subsale properties, for {cKey1 === 'sale' ? 'purchase' : cKey1},
                            encompassing cosy landed communities such as{" "}
                        </>
                    ),
                    content2: (
                        <>
                            {" "}
                            Strategically situated near essential amenities like schools,
                            shopping centres and recreational facilities, our properties
                            offer the perfect blend of convenience and accessibility.
                            Whether you're buying or renting a home,
                            Nextsix is your launchpad to a fulfilling homeownership experience. {commonContent}
                        </>
                    ),
                };
            } else if (hasArea && type !== "properties") {
                // Area content with types
                return {
                    content1: (
                        <>
                            Find your dream {type.toLowerCase()} for {cKey1} in{" "}
                            {cPlace1} today! Searching for the perfect fit is easy with our
                            user-friendly property search function. Explore a myriad of
                            other property types, ranging from{" "}
                        </>
                    ),
                    content2: (
                        <>
                            {" "}
                            from {state} - you're sure to find the ideal space to suit your
                            needs.
                            Nextsix is your trusted resource for buying or
                            renting your dream home. {commonContent}
                        </>
                    ),
                };
            } else if (!hasArea && type !== "properties") {
                // State content with types
                return {
                    content1: (
                        <>
                            Explore our extensive range of {type.toLowerCase()} for{" "}
                            {cKey1} in {cPlace1} and find your dream home today! Finding
                            your perfect fit is easy with our user-friendly property search
                            function. Our comprehensive platform also offers a diverse range
                            of other types of properties ranging from new and subsale{" "}
                        </>
                    ),
                    content2: (
                        <>
                            {" "}
                            - all within the vibrant state of {cPlace1}.
                            Whether you're buying or renting a home,
                            Nextsix is your launchpad to a fulfilling homeownership experience. {commonContent}
                        </>
                    ),
                };
            } else {
                // State content without types
                return {
                    content1: (
                        <>
                            Welcome to {cPlace1}, where comfort meets convenience.
                            Discover a plethora of both new and subsale property options
                            available for {cKey1}, ranging from charming landed communities
                            with{" "}
                        </>
                    ),
                    content2: (
                        <>
                            {" "}
                            Nestled in prime locations, our properties offer easy access to
                            essential amenities like schools, shopping centres, recreational
                            facilities and more. Nextsix is your trusted resource for buying or renting
                            your dream home. {commonContent}
                        </>
                    ),
                };
            }
        };

        const { content1, content2 } = getContent();

        const line1 =
            matchingLinks.length > 0 ? (
                getInternalLinks(matchingLinks)
            ) : (
                <span key={"terrace houses / linked houses / town houses"}>
                    <a href={processURL("terrace-link-townhouse")}>
                        terrace houses / linked houses / town houses
                    </a>
                </span>
            );

        const line2 =
            matchingLinks.length > 0
                ? getInternalLinks(
                    additionalLinks.filter(
                        (internal) => internal.link !== searchedType
                    )
                )
                : getInternalLinks(
                    additionalLinks.filter(
                        (internal) => internal.link !== "terrace-link-townhouse"
                    )
                );

        return (
            <span>
                {content1}
                {line1}{" "}
                {type === "properties" ? "to modern high-rise living with " : "to "}{" "}
                {line2}
                {content2}
            </span>
        );
    };

    return (
        <>
            {getWidth < 768 && <MobileAppAds />}
            <Header2 />
            <Container fluid className="property-list-empty" />
            <div className={clsx("propSearch-map-and-list",
                { 'ps-width-full': getWidth > 1024 && showMap }
            )}>
                <UrlNav />
                <Row>
                    {!loading &&
                        <>
                            <Col className="ps-sub-header-sticky" md={clsx("", { "12": getWidth < 1025 || !showMap }, { "6": showMap })}>
                                <SearchAndMapToggle search={search} toggleMap={toggleMap} />
                            </Col>
                            <Col md={clsx("",
                                { "12": getWidth < 1025 || !showMap },
                                { "6": showMap },
                            )}>
                                {properties.length > 0 && (<>
                                    <div className="propSearch-result-count">
                                        <div>
                                            <h1 className="propSearch-result-title">
                                                {string.capitalize(headerTag.type)} For {string.capitalize(headerTag.cKey1)} in {headerTag.cPlace1}
                                            </h1>
                                            <h2 className="propSearch-result-text"><span className="propSearch-result-number mb-0">{pageSummary.records} results </span>{headerTag.header2}</h2>
                                        </div>
                                        <Sorter />
                                    </div>

                                    {headerTag.show &&
                                        <div className=" greytext fontxs amount-property-found-styling" >
                                            <ContentDescription />
                                            {/* <span>{headerTag.paragraph1} Use our property search function to look for your perfect property. We also list other {headerTag.cPlace1} properties like 
                                            {MAPPING.InternalLinkings.map(internalLinking => (
                                                <span key={internalLinking.name}> <a href={processURL(internalLinking['link'])}> 
                                                {string.replaceString(internalLinking['name'], {"state": headerTag.cPlace1})}
                                                </a>{internalLinking['link'] === "/" ? "": ", "}</span>
                                            ))} that are currently for {headerTag.cKey1}. Search confidently with your trusted source of homes for sale and rent 
                                            at Nextsix. Our comprehensive platform allows you to conveniently search for the best prices of {search.target.replace(/-/g, ' ')}.</span> */}
                                        </div>
                                    }
                                </>
                                )}
                            </Col>
                        </>
                    }
                    {showMap &&
                        <Col
                            className={clsx("propSearch-show-map",
                                { "ps-width-full": getWidth < 769 },
                                { "ps-width-half": getWidth > 768 },
                            )}
                        >
                            {/* // className="Map-Warp" to check if needed */}
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: process.env.REACT_APP_GOOGLE_API_KEY
                                }}
                                defaultCenter={defaultLocation}
                                defaultZoom={14}
                                yesIWantToUseGoogleMapApiInternals//
                                onGoogleApiLoaded={({ map, maps }) => {
                                    search.target === global.PROJECT ?
                                        ProjectsMap(map, maps)
                                        : ModelsMap(map, maps)
                                }}
                                style={{ borderRadius: "15px" }}
                                options={{ gestureHandling: "greedy" }}
                            >
                            </GoogleMapReact>
                        </Col>
                    }
                    <div className={clsx("",
                        { "propSearch-hide-content": getWidth < 769 && showMap },
                        { "ps-padding-full ps-width-full": !showMap },
                        { "ps-padding-full ps-width-half": showMap },
                    )}>
                        {loading ?
                            <PropertyCardPlaceholder getWidth={getWidth} />
                            :
                            <>
                                {properties.length > 0 ?
                                    <div className={clsx("propSearch-show-listing custom-infinite-scrollbar2")} id="scrollableDiv">
                                        <Table
                                            page={page || 1}
                                            paginationCount={pageSummary.pages}
                                            pageSummary={pageSummary}
                                            handlePagination={handlePaginationClick}
                                        >
                                            <PropertyRows />
                                        </Table>
                                    </div>
                                    :
                                    <div className="propSearch-no-result">
                                        <img loading="lazy" src={ListingNotFound} alt="listing not found" /><br />
                                        {t('results-not-found')}
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Row>
            </div>
            <Footer2 />

            {modalPropertyStack && <Modal
                className={`${style.infoCenter_popup} agentSearch_flagModal`}
                isOpen={infoCenterModal}
                toggle={toggleInfoCenterModal}>
                <div className={`${style.mapBlock_infoCenterImg}`}>
                    <div className={`${style.mapBlock_infoCenter_header}`}>
                        <p className={`${style.mapBlock_infoCenter_area}`}>
                            {modalPropertyStack[0]?.masterName + " +" + modalPropertyStack.length}
                        </p>
                        <img loading="lazy"
                            className={`${style.mapBlock_infoCenter_infoIcon}`}
                            src={infoIconFlag}
                            alt="" />
                    </div>

                </div>
                <div className={`${style.infoCenter_popup_content}`}>
                    <div className={`${style.infoCenter_popup_close}`}><MdClose onClick={toggleInfoCenterModal} /></div>
                    <TabContent activeTab={"Property"} className={`${style.infoCenter_tabContent}`}>
                        <TabPane tabId="Property" className={`${style.infoCenter_tabContent_property} custom-infinite-scrollbar2`}>
                            {modalPropertyStack.map((property, i) => {
                                return (
                                    <div className={`${style.infoCenter_propCard}`} key={i}>
                                        <div className={`${style.propCard_top}`}>
                                            <div className={`${style.propCard_imgWrap}`}>
                                                <img loading="lazy" className={`${style.propCard_img}`} src={property.images[0]}
                                                    alt={property && (property.for == "Rent" || property.for == "Buy") &&
                                                        (property.type + " " + (property.for == "Rent" ? "For Rent" : "For Sale") + " in " + property.address?.area + " " + property.address?.state.replace(`Wilayah Persekutuan`, ``).trim())} />
                                            </div>
                                            <div className={`${style.propCard_topContent}`}>
                                                <a href={handleModalPopulateData(property)} className={`${style.redirectLink}`} rel="noopener noreferrer" target="_blank" onClick={() => localStorage.setItem('from', "map")}>
                                                    <div className={`${style.propCard_topContent_innerWrap}`}>
                                                        <p className={`${style.propCard_name}`}>{property.name}</p>
                                                        <div>
                                                            <p className={`${style.propCard_for}`}>{property.for === "Buy" ? "Buy" : property.for}</p>
                                                            {property.for.toLowerCase() === "rent" ?
                                                                <p className={`${style.propCard_price}`}>
                                                                    {`RM ${parseFloat(property.rent.monthlyRental).toLocaleString()}/month`}
                                                                </p>
                                                                : <p className={`${style.propCard_price}`}>
                                                                    RM {parseFloat(property.buy.price).toLocaleString()}
                                                                </p>
                                                            }
                                                            <div className={`${style.propCard_topContent_iconGroup}`}>
                                                                <div>
                                                                    {property.beds != "Bedrooms" && property.beds && (
                                                                        <Fragment>
                                                                            <img loading="lazy" className={`${style.propCard_topContent_icon}`} src={bed} alt="" />
                                                                            <span className={`${style.propCard_topContent_iconText}`}>{property.beds}
                                                                            </span>
                                                                        </Fragment>
                                                                    )}
                                                                    {property.baths != 0 && property.baths && (
                                                                        <Fragment>
                                                                            <img loading="lazy" className={`${style.propCard_topContent_icon}`} src={shower} alt="" />
                                                                            <span className={`${style.propCard_topContent_iconText}`}>{property.baths}</span>
                                                                        </Fragment>
                                                                    )}
                                                                    {property.buildup != 0 && property.buildup && (
                                                                        <Fragment>
                                                                            <img loading="lazy" className={`${style.propCard_topContent_icon}`} src={house} alt="" />
                                                                            <span className={`${style.propCard_topContent_iconText}`}>{property.buildup} ft.²</span>
                                                                        </Fragment>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <img loading="lazy" className={`${style.propCard_topContent_icon}`} src={calendar} alt="" />
                                                                    <span className={`${style.propCard_topContent_createdDate}`}>{moment(property.created).format("DD-MM-YYYY")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        {property.description && (
                                            <div className={`${style.propCard_middle}`}>
                                                <p className={`${style.propCard_description}`}>{property.description}</p>
                                            </div>
                                        )}
                                        <div className={`${style.propCard_bottom}`}>
                                            <div className={`${style.propCard_bottom_content}`}>
                                                <Avatar
                                                    className={`${style.propCard_avatar}`}
                                                    src={
                                                        property.agent.image
                                                            ? property.agent.image
                                                            : SetupData.profile.imgURL
                                                    }
                                                />
                                                <a href={goToAgentPage(property.agent)} className={`${style.propCard__agentInfo} ${style.redirectLink}`} rel="noopener noreferrer" target="_blank" onClick={() => localStorage.setItem('from', "map")}>
                                                    <span className={`${style.propCard__agentCompany}`}>{property.agent.renId ? property.agent.companyName : "Private Advertiser"}</span>
                                                    <span className={`${style.propCard__agentName}`}>{property.agent.nickname || property.agent.name}</span>
                                                </a>
                                            </div>
                                            <img loading="lazy" className={`${style.propCard_agentMobile_btn}`} onClick={() => handlePhone(property.agent.mobile)} src={phone} alt="" />
                                        </div>
                                    </div>
                                )
                            })}
                        </TabPane>
                    </TabContent>
                </div>
            </Modal>
            }
        </>
    )
}

const SearchAndMapToggle = ({ search, toggleMap }) => {
    const { t } = useTranslation();
    const history = useHistory();
    // const location = useLocation();
    // const locationState = location.state || {};

    const filterData = useSelector(state => state.seoData);
    const [newSearch, setNewSearch] = useState(search || { target: "properties-for-sale" });
    // const [searchBarVal, setSearchBarVal] = useState(locationState.searchBarVal || ""); //to store in location state to maintain search value when route change

    const propTitleOptions = (newSearch.target === global.AUCTION ?
        filterData.bidNowPropertyTypes : filterData.titles
    ) || [];

    const selectedPropTitle = useMemo(() => {
        const res = propTitleOptions.find(({ value }) => value === newSearch.titleOrType);
        return res || {};
    }, [propTitleOptions, newSearch.titleOrType]);

    const propTypeOptions = selectedPropTitle.type || [];

    const assignFilter = (name, value) => {
        setNewSearch(prevSearch => {
            const { page, ...others } = prevSearch
            //exclude page 
            return {
                ...others,
                [name]: value
            }
        });
    };

    const handleSelectPropType = (e) => {
        assignFilter("types", e)
    }

    const propertySuggestionDefaultText = () => {
        if (newSearch.freeText) return newSearch.freeText;
        if (newSearch.propertyName) return newSearch.propertyName;
        if (newSearch.area) return newSearch.area;
        if (newSearch.state) return newSearch.state;
        if (newSearch.aiSearchDisplay) return newSearch.aiSearchDisplay;
        if (newSearch.realestateMaster) return newSearch.masterName;
        // if (newSearch.area) return seoComponent.beautifyArea(newSearch.area);
        // if (newSearch.state) return seoComponent.beautifyState(newSearch.state);

        return '';
    };

    const handleCallback = (cb) => {
        console.log(cb)
        assignFilter("state", "");
        assignFilter("area", "");
        assignFilter("freeText", "");
        assignFilter("propertyName", "");
        assignFilter("aiSearchDisplay", "");
        assignFilter("aiSearchContext", null);
        assignFilter("realestateMaster", null);

        const group = cb.value.option.quickSearchGroup;
        const groupConstant = seoComponent.propertyGroupConstant;
        console.log(group)
        if (group === groupConstant.groupState || group === groupConstant.groupArea) {
            assignFilter("state", cb.value.option.quickSearchState);
            if (group === groupConstant.groupArea) assignFilter("area", cb.value.option.quickSearchArea);
        }
        else if (group === groupConstant.groupProperty) {
            assignFilter("propertyName", cb.value.value);
        }
        else if (group === groupConstant.groupPropertyMaster) {
            assignFilter("realestateMaster", cb.value.option._id);
        }
        else if (group === groupConstant.groupNone) {
            assignFilter("freeText", cb.value.value);
        }
        else if (group === groupConstant.groupAiProperty) {
            assignFilter("aiSearchDisplay", cb.value.value);
            assignFilter("aiSearchContext", cb.value.option.aiSearchContext);
        }
    };

    const handleNewSearch = () => {
        // console.log(newSearch)
        const newUrl = seoComponent.createSearchUrl(newSearch);
        // console.log(newUrl);
        // history.push('/test-properties', { searchBarVal })
        window.location = newUrl;
        // history.push(newUrl, { searchBarVal })
    }

    const SearchRedirection = () => {
        const toAgentPage = () => {
            history.push("/agent/all-states/all-areas/all-types");
        };

        return (
            <div className={`${style.filtersBlock_redirect} propSearch-redirect`}>
                <Button
                    className={`${style.active_redirectButton} ${style.filtersBlock_redirectButton}`}
                    value="Property"
                >
                    {t('property')}
                </Button>
                <Button
                    className={`${style.filtersBlock_redirectButton}`}
                    value="Agent"
                    onClick={toAgentPage}
                >
                    {t('agent')}
                </Button>
            </div>
        )
    }

    const TargetFilterDropdown = () => {
        const targetSelections = filterData.target || [];
        // console.log(targetSelections)
        const displayTarget = filterData.target?.find(({ value }) => value === newSearch.target);

        const targetSelectionOnclick = (select) => {
            const currTarget = search.target;
            const target = select.value;
            // Remain other values even new target is selected
            // Only available in between rent to sale or sale to rent
            if (
                (target === "properties-for-rent" ||
                    target === "properties-for-sale") &&
                (currTarget === "properties-for-rent" ||
                    currTarget === "properties-for-sale")
            )
                setNewSearch((prev) => ({
                    ...prev,
                    target: select.value,
                }));
            else setNewSearch({ target: select.value }); //clear other values when select new target
        };

        return (
            <UncontrolledButtonDropdown className="search-filter sub-search">
                <DropdownToggle
                    className="text-capitalize search-dropdown-toggle sub-search"
                    caret
                    color="transparent"
                    size="m"
                    onClick={handleButtonDropdown}
                >
                    <span className="search-filter-label sub-search">{displayTarget?.label}</span>
                </DropdownToggle>
                <DropdownMenu className={targetSelections.length > 4 ? "search-dropdown-menu-scroll" : "search-dropdown-menu"}>
                    {targetSelections.map((select, idx) => {
                        return (
                            <DropdownItem
                                key={idx}
                                onClick={() => targetSelectionOnclick(select)}
                            >
                                {select.label}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
                <div id="borderRight" />
            </UncontrolledButtonDropdown>
        )
    }

    const PropertyTitleDropdown = () => {
        const defaultValue = t('property-title');
        const options = propTitleOptions;
        const selectedOption = selectedPropTitle;
        const selectedValueDisplay = selectedOption?.title || defaultValue;

        const handleSelect = (value) => {
            assignFilter("titleOrType", value)
            if (value !== newSearch.titleOrType) handleSelectPropType([]);
        }
        return (
            <GeneralDropdownFilter
                selectedValueDisplay={selectedValueDisplay}
                options={options}
                handleSelect={handleSelect}
                defaultValue={defaultValue}
            />
        )
    }

    const PropertyTypeMultiSelect = propTypeOptions.length > 0 &&
        <div className="propertyList-multidropdown">
            <Multiselect
                isObject={false}
                options={propTypeOptions}
                placeholder={t('property-types')}
                showCheckbox={true}
                showArrow={true}
                selectedValues={newSearch.types || null}
                onSelect={handleSelectPropType}
                onRemove={handleSelectPropType}
                style={multiDropdownCss}
                customCloseIcon={<MdBackspace style={{ width: "25px", height: "15px" }} />}
                closeOnSelect={false}
                customArrow={true}
            />
        </div>

    const PriceRangeDropdown = () => {
        const buyPriceOptions = filterData.price?.buy || [];
        const rentPriceOptions = filterData.price?.rent || [];
        const options = newSearch.target === global.RENT ? rentPriceOptions : buyPriceOptions;
        const minPriceVal = newSearch.minPrice;
        const maxPriceVal = newSearch.maxPrice;

        const getDisplayedPrice = (price) => {
            return `RM${lib.currencyFormatter(Number(price))}`;
        }

        //price range displayed values
        const priceRangeDefaultVal = t('any-price');
        let priceRangeDisplayedVal = priceRangeDefaultVal;
        if (minPriceVal && maxPriceVal)
            priceRangeDisplayedVal = `${getDisplayedPrice(minPriceVal)} - ${getDisplayedPrice(maxPriceVal)}`;
        else if (minPriceVal)
            priceRangeDisplayedVal = `${getDisplayedPrice(minPriceVal)} +`;
        else if (maxPriceVal)
            priceRangeDisplayedVal = `RM 0 - ${getDisplayedPrice(maxPriceVal)}`;

        const MinPriceDropdown = () => {
            const defaultValue = t('min-price-any');
            const selectedOption = minPriceVal;
            const selectedValueDisplay = selectedOption ?
                <>
                    (Min) RM<NumberFormat displayType={"text"} thousandSeparator={true} value={selectedOption} readOnly />
                </>
                : defaultValue;

            const handleSelect = (value) => {
                assignFilter("minPrice", value)
            }
            return (
                <DropdownFilterLayout selectedValueDisplay={selectedValueDisplay} scrollable={options.length > 4}>
                    <DropdownItem onClick={() => handleSelect("")}>
                        {defaultValue}
                    </DropdownItem>
                    {options.map((option, idx) => (
                        <DropdownItem key={idx} onClick={() => handleSelect(option.value)}
                            disabled={Boolean(maxPriceVal && Number(maxPriceVal) < (Number(option.value)))}
                        >
                            <span>RM{option.label}</span>
                        </DropdownItem>
                    ))}
                </DropdownFilterLayout>
            )
        }

        const MaxPriceDropdown = () => {
            const defaultValue = t('max-price-any');
            const selectedOption = maxPriceVal;
            const selectedValueDisplay = selectedOption ?
                <>
                    (Max) RM<NumberFormat displayType={"text"} thousandSeparator={true} value={selectedOption} readOnly />
                </>
                : defaultValue;

            const handleSelect = (value) => {
                assignFilter("maxPrice", value)
            }
            return (
                <DropdownFilterLayout selectedValueDisplay={selectedValueDisplay} scrollable={options.length > 4}>
                    <DropdownItem onClick={() => handleSelect("")}>
                        {defaultValue}
                    </DropdownItem>
                    {options.map((option, idx) => (
                        <DropdownItem key={idx} onClick={() => handleSelect(option.value)}
                            disabled={Boolean(minPriceVal && Number(minPriceVal) > (Number(option.value)))}
                        >
                            <span>RM{option.label}</span>
                        </DropdownItem>
                    ))}
                </DropdownFilterLayout>
            )
        }

        return (
            <DropdownFilterLayout selectedValueDisplay={priceRangeDisplayedVal}>
                <div className="search-dropdown-menu-double-select-container">
                    <MinPriceDropdown />
                    <MaxPriceDropdown />
                </div>
            </DropdownFilterLayout>
        )
    }

    const BuildUpRangeDropdown = () => {
        const options = filterData.buildup || [];
        const minBuildUp = newSearch.minBuildup;
        const maxBuildUp = newSearch.maxBuildup;

        const getDisplayedBuildUp = (num) => {
            const plusSign = num.includes("+");
            return `${lib.currencyFormatter2Digits(num.replace('+', ''))}${plusSign ? "+" : ""} (sqft)`;
        }

        //range displayed values
        const rangeDefaultVal = t('any-sqft');
        let rangeDisplayedVal = rangeDefaultVal;
        if (minBuildUp && maxBuildUp)
            rangeDisplayedVal = `${getDisplayedBuildUp(minBuildUp)} - ${getDisplayedBuildUp(maxBuildUp)}`;
        else if (minBuildUp)
            rangeDisplayedVal = `${getDisplayedBuildUp(minBuildUp)} +`;
        else if (maxBuildUp)
            rangeDisplayedVal = `0 (sqft) - ${getDisplayedBuildUp(maxBuildUp)}`;

        const MinBuildUpDropdown = () => {
            const defaultValue = t('min-built-up-any');
            const selectedOption = minBuildUp;
            const selectedValueDisplay = selectedOption ?
                <NumberFormat displayType={"text"} prefix={"(Min) "} suffix={`${selectedOption.includes("+") ? "+" : ""} sqft`} thousandSeparator={true} value={selectedOption} readOnly />
                : defaultValue;

            const handleSelect = (value) => {
                assignFilter("minBuildup", value)
            }
            return (
                <DropdownFilterLayout selectedValueDisplay={selectedValueDisplay} scrollable={options.length > 4}>
                    <DropdownItem onClick={() => handleSelect("")}>
                        {defaultValue}
                    </DropdownItem>
                    {options.map((option, idx) => (
                        <DropdownItem key={idx} onClick={() => handleSelect(option.value)}
                            disabled={Boolean(maxBuildUp && Number(maxBuildUp.replace('+', '')) < (Number(option.value.replace('+', ''))))}
                        >
                            <span>{option.label} sqft</span>
                        </DropdownItem>
                    ))}
                </DropdownFilterLayout>
            )
        }

        const MaxBuildUpDropdown = () => {
            const defaultValue = t('max-built-up-any');
            const selectedOption = maxBuildUp;
            const selectedValueDisplay = selectedOption ?
                <NumberFormat displayType={"text"} prefix={"(Max) "} suffix={`${selectedOption.includes("+") ? "+" : ""} sqft`} thousandSeparator={true} value={selectedOption} readOnly />
                : defaultValue;

            const handleSelect = (value) => {
                assignFilter("maxBuildup", value)
            }
            return (
                <DropdownFilterLayout selectedValueDisplay={selectedValueDisplay} scrollable={options.length > 4}>
                    <DropdownItem onClick={() => handleSelect("")}>
                        {defaultValue}
                    </DropdownItem>
                    {options.map((option, idx) => (
                        <DropdownItem key={idx} onClick={() => handleSelect(option.value)}
                            disabled={Boolean(minBuildUp && Number(minBuildUp.replace('+', '')) > (Number(option.value.replace('+', ''))))}
                        >
                            <span>{option.label} sqft</span>
                        </DropdownItem>
                    ))}
                </DropdownFilterLayout>
            )
        }

        return (
            <DropdownFilterLayout selectedValueDisplay={rangeDisplayedVal}>
                <div className="search-dropdown-menu-double-select-container">
                    <MinBuildUpDropdown />
                    <MaxBuildUpDropdown />
                </div>
            </DropdownFilterLayout>
        )
    }

    const LandAreaRangeDropdown = () => {
        const options = filterData.landArea || [];
        const minLandArea = newSearch.minLandArea;
        const maxLandArea = newSearch.maxLandArea;

        const getDisplayedValue = (num) => {
            const plusSign = num.includes("+");
            return `${lib.currencyFormatter2Digits(num.replace('+', ''))}${plusSign ? "+" : ""} (sqft)`;
        }

        //range displayed values
        const rangeDefaultVal = t('land-area-any-sqft');
        let rangeDisplayedVal = rangeDefaultVal;
        if (minLandArea && maxLandArea)
            rangeDisplayedVal = `${getDisplayedValue(minLandArea)} - ${getDisplayedValue(maxLandArea)}`;
        else if (minLandArea)
            rangeDisplayedVal = `${getDisplayedValue(minLandArea)} +`;
        else if (maxLandArea)
            rangeDisplayedVal = `0 (sqft) - ${getDisplayedValue(maxLandArea)}`;

        const MinLandAreaDropdown = () => {
            const defaultValue = t('min-land-area-any');
            const selectedOption = minLandArea;
            const selectedValueDisplay = selectedOption ?
                <NumberFormat displayType={"text"} prefix={"(Min) "} suffix={`${selectedOption.includes("+") ? "+" : ""} sqft`} thousandSeparator={true} value={selectedOption} readOnly />
                : defaultValue;

            const handleSelect = (value) => {
                assignFilter("minLandArea", value)
            }
            return (
                <DropdownFilterLayout selectedValueDisplay={selectedValueDisplay} scrollable={options.length > 4}>
                    <DropdownItem onClick={() => handleSelect("")}>
                        {defaultValue}
                    </DropdownItem>
                    {options.map((option, idx) => (
                        <DropdownItem key={idx} onClick={() => handleSelect(option.value)}
                            disabled={Boolean(maxLandArea && Number(maxLandArea.replace('+', '')) < (Number(option.value.replace('+', ''))))}
                        >
                            <span>{option.label} sqft</span>
                        </DropdownItem>
                    ))}
                </DropdownFilterLayout>
            )
        }

        const MaxLandAreaDropdown = () => {
            const defaultValue = t('max-land-area-any');
            const selectedOption = maxLandArea;
            const selectedValueDisplay = selectedOption ?
                <NumberFormat displayType={"text"} prefix={"(Max) "} suffix={`${selectedOption.includes("+") ? "+" : ""} sqft`} thousandSeparator={true} value={selectedOption} readOnly />
                : defaultValue;

            const handleSelect = (value) => {
                assignFilter("maxLandArea", value)
            }
            return (
                <DropdownFilterLayout selectedValueDisplay={selectedValueDisplay} scrollable={options.length > 4}>
                    <DropdownItem onClick={() => handleSelect("")}>
                        {defaultValue}
                    </DropdownItem>
                    {options.map((option, idx) => (
                        <DropdownItem key={idx} onClick={() => handleSelect(option.value)}
                            disabled={Boolean(minLandArea && Number(minLandArea.replace('+', '')) > (Number(option.value.replace('+', ''))))}
                        >
                            <span>{option.label} sqft</span>
                        </DropdownItem>
                    ))}
                </DropdownFilterLayout>
            )
        }

        return (
            <DropdownFilterLayout selectedValueDisplay={rangeDisplayedVal}>
                <div className="search-dropdown-menu-double-select-container">
                    <MinLandAreaDropdown />
                    <MaxLandAreaDropdown />
                </div>
            </DropdownFilterLayout>
        )
    }

    const BedroomDropdown = () => {
        const defaultValue = t('bedrooms-any');
        const options = filterData.beds || [];
        const selectedOption = newSearch.bed;
        const selectedValueDisplay = selectedOption ?
            `${t('bedroom')} ${selectedOption}`
            : defaultValue;

        const handleSelect = (value) => {
            assignFilter("bed", value)
        }
        return (
            <GeneralDropdownFilter
                selectedValueDisplay={selectedValueDisplay}
                options={options}
                handleSelect={handleSelect}
                defaultValue={defaultValue}
            />
        )
    }

    const BathroomDropdown = () => {
        const defaultValue = t('bathrooms-any');
        const options = filterData.baths || [];
        const selectedOption = newSearch.baths;
        const selectedValueDisplay = selectedOption ?
            `${t('bathroom')} ${selectedOption}`
            : defaultValue;

        const handleSelect = (value) => {
            assignFilter("baths", value)
        }
        return (
            <GeneralDropdownFilter
                selectedValueDisplay={selectedValueDisplay}
                options={options}
                handleSelect={handleSelect}
                defaultValue={defaultValue}
            />
        )
    }

    const CarparkDropdown = () => {
        const defaultValue = t('carparks-any');
        const options = filterData.carparks || [];
        const selectedOption = newSearch.carParks;
        const selectedValueDisplay = selectedOption ?
            `${t('carpark')} ${selectedOption}`
            : defaultValue;

        const handleSelect = (value) => {
            assignFilter("carParks", value)
        }
        return (
            <GeneralDropdownFilter
                selectedValueDisplay={selectedValueDisplay}
                options={options}
                handleSelect={handleSelect}
                defaultValue={defaultValue}
            />
        )
    }

    const FurnishingDropdown = () => {
        const defaultValue = t('furnishing-any');
        const options = filterData.furnishing || [];
        const selectedOption = newSearch.furnishing;
        const selectedValueDisplay = selectedOption || defaultValue;

        const handleSelect = (value) => {
            assignFilter("furnishing", value)
        }
        return (
            <GeneralDropdownFilter
                selectedValueDisplay={selectedValueDisplay}
                options={options}
                handleSelect={handleSelect}
                defaultValue={defaultValue}
            />
        )
    }

    const TenureDropdown = () => {
        const defaultValue = t('tenure-any');
        const options = filterData.tenure || [];
        const selectedOption = newSearch.tenure;
        const selectedValueDisplay = selectedOption || defaultValue;

        const handleSelect = (value) => {
            assignFilter("tenure", value)
        }
        return (
            <GeneralDropdownFilter
                selectedValueDisplay={selectedValueDisplay}
                options={options}
                handleSelect={handleSelect}
                defaultValue={defaultValue}
            />
        )
    }

    const MoreFilter = () => {
        const dropdownDisplay = t('more-filter');
        return (
            <DropdownFilterLayout selectedValueDisplay={dropdownDisplay}>
                <div className="search-dropdown-menu-double-select-container">
                    <BathroomDropdown />
                    <CarparkDropdown />
                    <FurnishingDropdown />
                    <TenureDropdown />
                </div>
            </DropdownFilterLayout>
        )
    }

    const AuctionDateRange = () => {
        const [startDate, setStartDate] = useState(newSearch.auctionStart ? new Date(newSearch.auctionStart) : null);
        const [endDate, setEndDate] = useState(newSearch.auctionEnd ? new Date(newSearch.auctionEnd) : null);

        const handleSelectDate = (dates) => {
            const [start, end] = dates;
            // console.log(start, end)
            setStartDate(start);
            setEndDate(end);
            if (start && end) {
                assignFilter("auctionStart", startDate);
                assignFilter("auctionEnd", end)
            } else if (!start && !end) {
                assignFilter("auctionStart", null);
                assignFilter("auctionEnd", null)
            }
        }

        return (
            <div className="auction-date-range">
                <DatePicker
                    dateFormat="dd/MMM/yyyy"
                    onChange={handleSelectDate}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date()}
                    selectsRange={true}
                    isClearable={true}
                    placeholderText="Auction Date"
                    className="auction-date-range-input"
                    wrapperClassName="auction-date-range-wrapper"
                    calendarClassName="auction-date-range-calendar"
                />
            </div>
        )
    }

    return (
        <>
            <div className="propSearch-toggle-and-search">
                <div className="propSearch-redirect-and-search">
                    <SearchRedirection />
                    <div className="property-search-bar-col-padding">
                        <div className="property-search-content">
                            <div className="property-search-bar">
                                <TargetFilterDropdown />
                                {/* free text search and suggestion list */}
                                <PropertySuggestion
                                    callback={handleCallback}
                                    callbackId={"query"}
                                    defaultText={propertySuggestionDefaultText()}
                                    target={newSearch.target}
                                    searchEnter={handleNewSearch}
                                />
                                <Button className="search-button-action" onClick={handleNewSearch}>
                                    <BsSearch />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <span className="ps-map-switch-text">Show Map</span>
                    <label className="ps-map-switch">
                        <input
                            type="checkbox"
                            onChange={toggleMap}
                        />
                        <span className="ps-map-slider round"></span>
                    </label>
                </div>
            </div>
            <div>
                {newSearch.target !== global.PROJECT &&
                    <Col className="filter-selection-col" md={12}>
                        <PropertyTitleDropdown />
                        <PriceRangeDropdown />
                        <BuildUpRangeDropdown />
                        {newSearch.target === global.AUCTION ?
                            <>
                                <LandAreaRangeDropdown />
                                <AuctionDateRange />
                            </> :
                            <>
                                <BedroomDropdown />
                                <MoreFilter />
                                {PropertyTypeMultiSelect}
                            </>
                        }
                    </Col>
                }
            </div>
        </>

    )
}

const MobileAppAds = () => {
    const dispatch = useDispatch();
    const emblem = useSelector(state => state.emblem);

    const closeBanner = () => {
        dispatch(close("close"));
    }
    return (
        <>
            {/* mobile app advertisement */}
            {emblem === "open" &&
                <div className="emblem-wrapper">
                    <div className="emblem-left-content">
                        <Button className="emblem-close-btn" onClick={() => closeBanner()}><MdClose /></Button>
                        <img loading="lazy" className="emblem-logo" src={nextsixAppEmblem} alt="" />
                        <div className="emblem-left-textbox">
                            <span className="emblem-left-text-top">NextSix App</span>
                            <span className="emblem-left-text-btm">Find properties and agents faster</span>
                        </div>
                    </div>
                    <div className="emblem-right-content">
                        <a className="emblem-download-btn" href="https://apps.nextsix.com/users/reg">Use Now</a>
                    </div>
                </div>
            }
        </>
    )
}

const GeneralDropdownFilter = ({ selectedValueDisplay, options, handleSelect, defaultValue }) => {
    return (
        <DropdownFilterLayout selectedValueDisplay={selectedValueDisplay} scrollable={options.length > 4}>
            <DropdownItem onClick={() => handleSelect("")}>
                {defaultValue}
            </DropdownItem>
            {options.map((option, idx) => (
                <DropdownItem key={idx} onClick={() => handleSelect(option.value || option)} >
                    {option.label || option.title || option}
                </DropdownItem>
            ))}
        </DropdownFilterLayout>
    )
}

const DropdownFilterLayout = ({ children, selectedValueDisplay, scrollable }) => {

    return (
        <span className="propSearch-filter-dropdown">
            <UncontrolledButtonDropdown className="search-dropdown-filter">
                <DropdownToggle
                    caret
                    color="transparent"
                    size="m"
                    className="text-capitalize propSearch-dropdown-toggle"
                    onClick={handleButtonDropdown}
                >
                    <span className="propSearch-filter-label">
                        {selectedValueDisplay}
                    </span>
                </DropdownToggle>
                <DropdownMenu className={scrollable ? "search-dropdown-menu-scroll" : "search-dropdown-menu"}>
                    {children}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        </span>
    )
}

const PropertyCardPlaceholder = ({ getWidth }) => {
    return (
        <>
            <div className={clsx("propSearch-listing",
                { "propSearch-listing-five-col": getWidth > 1199 },
                { "propSearch-listing-three-col": getWidth > 1199 },
                { "propSearch-listing-four-col": getWidth < 1200 },
                { "propSearch-listing-two-col": getWidth < 1200 },
                { "propSearch-listing-two-col": getWidth < 769 },
                { "propSearch-listing-two-col": getWidth < 769 },
            )}>
                {[1, 2, 3, 4, 5, 6, 7, 8].map(i => {
                    return (
                        <Card className="agentsp-list-placeholder-card" key={i}>
                            <Placeholder
                                className="card-image"
                                containerClassName="agentsp-map-placeholder-wrapper"
                            />
                            <CardBody>
                                <CardTitle className="w-75">
                                    <Placeholder />
                                </CardTitle>
                                <CardText>
                                    <Placeholder />
                                </CardText>
                                <CardText className="w-25">
                                    <Placeholder />
                                </CardText>
                            </CardBody>
                        </Card>
                    )
                })}
            </div>
        </>
    )
}

const multiDropdownCss = {
    chips: {
        background: '#d4cccc',
        color: "#000",
        padding: "5px 15px 5px 15px",
        borderRadius: "5px",
        margin: "0 0 0 5px"
    },
    multiselectContainer: {
        color: '#58595b',
        width: "fit-content",
        borderColor: "#939598",
        borderRadius: '7px',
        display: "inline-block",
        fontSize: "12px",
        margin: "0 12px 0 0"
    },
    searchBox: {
        borderColor: '#939598',
        color: "#939598",
        borderRadius: '7px',
        minHeight: "31px",
        border: "1px solid",
        display: "flex",
        flexWrap: "wrap",
    },
    optionContainer: { // To change css for option container 
        border: "1px solid",
    },
}